import * as React from 'react'
import {HiExclamation} from 'react-icons/hi'
import {Global} from '@emotion/react'
import tw from 'twin.macro'

import {Button} from '@/client/core/components/button'
import {Card, Spacer} from '@/client/core/components/layout'
import {Logo} from '@/client/core/components/logo'

function Layout({children}: {children: React.ReactNode}) {
  return (
    <>
      <Global styles={{html: tw`font-size[16px]`}} />
      <div tw="h-20 flex items-center justify-center">
        <Logo />
      </div>
      <div tw="flex items-center justify-center min-height[calc(100vh - 12rem)]">
        {children}
      </div>
    </>
  )
}

/**
 * Catch 404 and other fatal app errors
 */
function NotFoundPage() {
  return (
    <Layout>
      <main tw="container-md" role="alert">
        <Card variant="focus">
          <div tw="flex flex-col items-center text-center">
            <HiExclamation tw="text-4xl text-error" />
            <h2 tw="heading-lg">페이지를 찾을 수 없습니다</h2>
          </div>

          <Spacer y={3} />

          <Button
            size="lg"
            tw="w-full"
            onClick={() => {
              window.location.assign('/')
            }}
          >
            홈으로 돌아가기
          </Button>
        </Card>
      </main>
    </Layout>
  )
}

export default NotFoundPage
